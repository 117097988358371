import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export const UploadError = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Error al cargar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Ocurrio un problema al cargar los resultados, vuelve a cargar para verificar</p>
      </Modal.Body>
      <Modal.Footer>
        <Button  
          style={{marginRight: '10px', backgroundColor: '#933A16', borderColor: '#933A16'}}
          onClick={handleClose}>
          Volver a cargar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginAdmin } from 'store/slices/login/loginThunks';
import { useNavigate } from 'react-router-dom';

export const useLogin = (initState) => {
  const [state, setState] = useState(initState);
  const { errors, logedIn, token } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  

  useEffect(() => {
    if (logedIn) {
      localStorage.setItem('token', token);
      navigate('/');
    }
  }, [logedIn, navigate, token]);

  const handlerChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  const handlerSubmit = e => {
    e.preventDefault();
    const authParams = {
      rut: state.dni,
      password: state.password,
      origin: 'tele-doc'
    };
    dispatch(loginAdmin(authParams));
  };

  return {
    state,
    errors,
    handlerChange,
    handlerSubmit
  };
}
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    validateMassiveExcelAction,
    loadExcelDataAction,
    validateMassiveScheduleExcelAction,
    loadScheduleExcelDataAction,
    getExcelFormatAction,
    cleanExcelAction,
    getScheduleExcelFormatAction,
} from 'store/slices/massiveUploads/thunks';

const useUploadFile = (initState) => {
    const [state, setState] = useState(initState);
    const [localLoading, setLocalLoading] = useState(null);
    const [uploadCheckFile, setUploadCheckFile] = useState(false);
    const dispatch = useDispatch();
    const {
        loading,
        holdings,
        companies,
        branches,
        admins,
        schedules,
        responseToLoad,
        excelStatusOk,
        errorMsg,
        excel,
        errorStatus,
        loadExcelStatus,
        msgLoadExcelStatus,
    } = useSelector(state => state.massiveUploads);
    
    const hiddenFileInput = useRef(null);

    useEffect(() => {
        if (excel !== null) {
            window.open(excel, '_blank');
            dispatch(cleanExcelAction()); 
        }
    }, [excel])
    

    const handleClickFile = e => {
        hiddenFileInput.current.click();
    };

    const handleClick = (page) => {
        if (page === 'massive') {
            dispatch(getExcelFormatAction());
        }
        if (page === 'schedule') {
            dispatch(getScheduleExcelFormatAction());
        }
    };

    const handleChangeFile = e => {
        const fileUploaded = e.target.files[0];
        setState({
            ...state,
            file: fileUploaded
        });
        setUploadCheckFile(true);
    };

    const sendExcelFile = type => e => {
        e.preventDefault();
        const { file } = state;
        if (file === '') {
            alert('debe subir un excel');
            return;
        }
        const body = new FormData();
        body.append('file', file);
        if (type === 'massive') {
            dispatch(validateMassiveExcelAction(body));       
        }
        if (type === 'schedule') {
            dispatch(validateMassiveScheduleExcelAction(body));
        }
    }

    const loadExcelData = type => e => {
        e.preventDefault();
        if (responseToLoad === null) {
            alert('no se puede cargar data en base de datos');
        }
        if (type === 'massive') {
            dispatch(loadExcelDataAction(responseToLoad));
        }
        if (type === 'schedule') {
            dispatch(loadScheduleExcelDataAction(responseToLoad));
        }
        setLocalLoading(true);
        setTimeout(() => {
            setLocalLoading(null);
        }, 200);
        setUploadCheckFile(false);
        setState({
            ...state,
            file: '',
        });
    }

    return {
        state,
        uploadCheckFile,
        // slice state
        loading,
        holdings,
        companies,
        branches,
        admins,
        schedules,
        responseToLoad,
        excelStatusOk,
        errorMsg,
        errorStatus,
        loadExcelStatus,
        msgLoadExcelStatus,
        // slice state end
        localLoading,
        hiddenFileInput,
        handleChangeFile,
        handleClickFile,
        handleClick,
        sendExcelFile,
        loadExcelData,
    }
}
 
export default useUploadFile;
import { adminAuth, verifyTokenSession } from './loginService'
import {
  initLogin,
  loginSuccess,
  errorLogin,
  initValidateSession,
  validSession,
  invalidSession,
  cleanErrorLogin,
  cleanLogin
} from './loginSlice'

export const loginAdmin = authParams => async dispatch => {
  dispatch(initLogin())
  try {
    const response = await adminAuth(authParams)
    if (response.status === 201 && response.data.statusCode !== 404) {
      dispatch(cleanErrorLogin());
      dispatch(loginSuccess({ token: response.data.token }))
    } else {
      dispatch(errorLogin({ errorMsg: response.data.error }))
    }
  } catch (error) {
    dispatch(errorLogin({ errorMsg: error }))
  }
}

export const verifyToken = (navigate) => async dispatch => {
  try {
    dispatch(initValidateSession())
    const token = localStorage.getItem('token')
    if (token) {
      const response = await verifyTokenSession(token);
      if (response.data?.statusCode === 200) {
          dispatch(cleanErrorLogin());
          dispatch(validSession());
      } else {
          dispatch(invalidSession());
          navigate('/login');
      }
    } else {
      dispatch(invalidSession());
      navigate('/login');
  }
  } catch (error) {
    dispatch(cleanLogin())
  }
}
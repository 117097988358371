import axiosClient from "../../../config/axios";

const validateExcelResults = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const headers = {
      'Content-Type': 'multipart/form-data',
  } ;
    const response = await axiosClient.post('/massive-results/validate-massive-results', formData, {headers});
    return response;
  } catch (error) {
    return error;
  }
}

const uploadJsonResults = async (results, nextStage) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    };
    const response = await axiosClient.post('/massive-results/upload-json-results', {results, nextStage}, {headers});
    return response;
  } catch (error) {
    return error;
  }
}

export {
  validateExcelResults,
  uploadJsonResults
}
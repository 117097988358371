import React from 'react';
import { Modal, Button, Table  } from 'react-bootstrap';

export const UploadStageError = ({ show, errorStagePatients , handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Error al pasar de etapa</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Se cargaron los resultados, pero los siguientes pacientes no pudieron pasar de etapa:</p>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Rut</th>
              <th>Error</th>
            </tr>
          </thead>
          <tbody>
            {errorStagePatients.map((patient, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{patient.rut}</td>
                <td>{patient.error}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleClose}>
          Volver a la carga
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


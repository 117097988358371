import { createSlice } from '@reduxjs/toolkit';

export const massiveResultsSlice = createSlice({
    name: 'massiveResults',
    initialState: {
        loading: false,
        excelResults: null,
        error: null,
        excelSample: null,
        jsonResults: null,
        uploadSuccess: false,
        uploadError: false,
        errorStagePatients: []
    },
    reducers: {
        initGetExcelResults: state => {
            state.loading = true;
        },
        getExcelResults: (state, action) => {
            state.loading = false;
            state.jsonResults = action.payload;
        },
        errorGetExcelResults: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        getResultsExcelFormat: (state) => {
            state.loading = false;
            state.excelSample = process.env.REACT_APP_BACKEND_URL + '/massive-results/get-results-excel-format'
        },
        cleanExcel: state => {
          state.excelSample = null;
        },
        cleanJsonResults: state => {
            state.jsonResults = null;
        },
        initUploadResults: state => {
            state.loading = true;
        },
        uploadResultsSuccess: (state, action) => {
            state.uploadSuccess = true;
            state.jsonResults = null;
            state.loading = false;
            state.errorStagePatients = action.payload;
        },
        uploadResultsError: state => {
            state.loading = false;
            state.jsonResults = null;
            state.uploadError = true;
        },
        cleanUploadSuccess: state => {
            state.uploadSuccess = false;
        },
        cleanUploadError: state => {
            state.uploadError = false;
        },
        cleanUploadStageError: state => {
            state.errorStagePatients = [];
        }
    },
});

export const { 
    initGetExcelResults, 
    getExcelResults, 
    errorGetExcelResults,
    getResultsExcelFormat, 
    cleanExcel, 
    cleanJsonResults,
    initUploadResults,
    uploadResultsSuccess ,
    uploadResultsError,
    cleanUploadSuccess,
    cleanUploadError,
    cleanUploadStageError,
    errorStagePatients
} = massiveResultsSlice.actions;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import '../assets/css/Home.css';

const FloatingHomeIcon = () => {
  const navigate = useNavigate();

  return (
    <div className="floating-icon" onClick={() => navigate('/')}>
      <FaHome style={{ fontSize: '30px', color: '#0050ff' }} title="Go to Home" />
    </div>
  );
};

export default FloatingHomeIcon;
import { createSlice } from '@reduxjs/toolkit';

export const massiveUploadsSlice = createSlice({
    name: 'massiveUploads',
    initialState: {
        loading: false,
        holdings: null,
        companies: null,
        branches: null,
        admins: null,
        schedules: null,
        responseToLoad: null,
        excelStatusOk: false,
        errorMsg: null,
        errorStatus: false,
        loadExcelStatus: false,
        excel: null,
        msgLoadExcelStatus: null,
    },
    reducers: {
        initGetExcelData: state => {
            state.loading = true;
        },
        getExcelData: (state, action) => {
            state.loading = false;
            state.excelStatusOk = true;
            state.holdings = action.payload.holdingResponse;
            state.companies = action.payload.companyResponse;
            state.branches = action.payload.branchResponse;
            state.admins = action.payload.adminsResponse;
            state.schedules = action.payload.schedulesResponse;
            state.responseToLoad = action.payload.responseToLoad;
        },
        errorGetExcelData: (state, action) => {
            state.loading = false;
            state.excelStatusOk = false;
            state.errorMsg = action.payload.msg;
            state.errorStatus = true;
            state.holdings = null;
            state.companies = null;
            state.branches = null;
            state.admins = null;
            state.schedules = null;
            state.responseToLoad = null;
        },
        initLoadExcelData: state => {
            state.loadExcelStatus = true;
        },
        loadExcelData: (state, action) => {
            state.loadExcelStatus = false;
            state.excelStatusOk = false;
            state.msgLoadExcelStatus = action.payload.msg;
        },
        initGetExcelFormat: state => {
            state.loading = true;
        },
        getExcelFormat: (state) => {
            state.loading = false;
            state.excel = process.env.REACT_APP_BACKEND_URL + '/massive-uploads/get-excel-format';
        },
        initGetScheduleExcelFormat: state => {
            state.loading = true;
        },
        getScheduleExcelFormat: state => {
            state.loading = false;
            state.excel = process.env.REACT_APP_BACKEND_URL + '/massive-uploads/get-schedule-excel-format';
        },
        cleanExcel: state => {
            state.excel = null;
        }
    }
});

export const {
    initGetExcelData,
    getExcelData,
    errorGetExcelData,
    initLoadExcelData,
    loadExcelData,
    initGetExcelFormat,
    getExcelFormat,
    cleanExcel,
    initGetScheduleExcelFormat,
    getScheduleExcelFormat,
} = massiveUploadsSlice.actions;
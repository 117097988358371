import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  getResultsExcelFormatAction, 
  cleanExcelAction, 
  validateExcelResultsAction,
  cleanJsonResultsAction,
  uploadJsonResultsAction,
  cleanUploadSuccessAction,
  cleanUploadErrorAction,
  cleanUploadStageErrorAction 
} from 'store/slices/massiveResults/massiveResultsThunks';

export const useUploadResults = (initState) => {
  const [state, setState] = useState(initState);
  const [fileName, setFileName] = useState('');
  const [excelResults, setExcelResults] = useState(null);
  const [uploadCheckFile, setUploadCheckFile] = useState(false);
  const [nextStage, setNextStage] = useState([]);

  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  
  const {
    loading,
    excelSample,
    jsonResults,
    uploadSuccess,
    uploadError,
    errorStagePatients
  } = useSelector(state => state.massiveResults);

  useEffect(() => {
    if (excelSample !== null) {
        window.open(excelSample, '_blank');
        dispatch(cleanExcelAction()); 
    } 
  }, [excelSample])

  useEffect(() => {
    if (jsonResults !== null) {
      setState(jsonResults);
      setNextStage(jsonResults.nextStage);
    }
  }, [jsonResults])

  const handleFileChange = (e) => {
    setExcelResults(e.target.files[0]);
    setFileName(e.target.files[0].name);
    setUploadCheckFile(true);
  };

  const handleClickFile = e => {
    hiddenFileInput.current.click();
  };

  const handleDowloadExcel = () => {
    dispatch(getResultsExcelFormatAction());
  };

  const handleValidateResults = () => {
    dispatch(validateExcelResultsAction(excelResults));
  };

  const handleCheckPatient = (dni) => {
    if (nextStage.includes(dni)) {
      const newNextSage = nextStage.filter((item) => item !== dni);
      setNextStage(newNextSage);
    }else{
      setNextStage([...nextStage, dni]);
    }
  }

  const handleLoadNewExcel = () => {
    setFileName('');
    setExcelResults(null);
    setUploadCheckFile(false);
    dispatch(cleanJsonResultsAction());
  }

  const handleLoadResults = () => {
    dispatch(uploadJsonResultsAction(jsonResults.patientsArray, nextStage));
  }

  const handleClosePopup = () => {
    setFileName('');
    setExcelResults(null);
    hiddenFileInput.current.value = null;
    setUploadCheckFile(false);
    dispatch(cleanUploadSuccessAction());
    dispatch(cleanUploadErrorAction());
    dispatch(cleanUploadStageErrorAction());
  }

  return {
    loading,
    fileName,
    jsonResults,
    excelResults,
    hiddenFileInput,
    uploadCheckFile,
    uploadSuccess,
    uploadError,
    errorStagePatients,
    handleDowloadExcel,
    handleValidateResults,
    handleFileChange,
    handleClickFile,
    handleLoadNewExcel,
    handleLoadResults,
    handleClosePopup,
    handleCheckPatient
  };

};

import React from 'react';
import { useUploadExamFile} from 'hooks/useUploadFile/useUploadExamFiles';
import { Upload, Trash  } from 'react-bootstrap-icons'
import { LoadingSpinner } from '../MassiveResults/LoadingComponent';
import '../../assets/css/FileCard.css';
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  Form,
  Button,
} from 'react-bootstrap';

const MassiveExamFilesView = () => {
    const { 
        loading,
        selectedFiles,
        handleFilesChange,
        handleRemoveFile,
        hiddenFileInput,
        handleClickFile,
        handleUploadFiles,
        missingLoads,
        successLoads
    } = useUploadExamFile();


  return ( 
    <Container className='mt-3'>
    <Row>
        {loading ? (<LoadingSpinner />) : null}
        <Col sm={12} lg={12} md={12} className='mt-3'>
            <Card>
                <Card.Header className='text-center'>
                    <h5 className='title-module' >Módulo de cargas masivas de exámenes pdf</h5>
                </Card.Header>
                <Card.Body className='border-top'>
                    <Col sm={12} lg={12} md={12} className=''>
                        {missingLoads.length < 1 && successLoads.length < 1 ? (
                            <div className='d-flex mb-3'>
                                <Form.Label className='mb-3 title-physical-exams mt-3'>Para hacer cargas masivas suba los <strong>Pdfs</strong>: </Form.Label>
                                <Button
                                    onClick={ handleClickFile }
                                    className='btn-upload-file'
                                >
                                 <Upload />
                                 <p>Seleccionar archivos</p>
                                </Button>
                                <Form.Control 
                                    type='file'
                                    name='file'
                                    ref={hiddenFileInput}
                                    onChange={handleFilesChange}
                                    style={{display:'none'}} 
                                    multiple
                                />
                            </div>
                        ) : null}
                        <Row className='gx-1'>
                            {selectedFiles.map((file, index) => (
                                <Col key={index} sm={12} md={6} lg={2} className='mb-3'>
                                    <Card className='file-card'>
                                        <Card.Body className='d-flex justify-content-between align-items-center'>
                                            <p className='mb-0'>{file.name}</p>
                                            <Trash 
                                                onClick={() => handleRemoveFile(index)}
                                                className='text-danger' 
                                                style={{ cursor: 'pointer' }} 
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        {successLoads?.length > 0 ? (
                            <div className='text-center'>
                                <p className='text-success' style={{fontSize:"18px"}}><strong>Los siguientes archivos se cargaron correctamente:</strong></p>
                                <Row className='gx-1'>
                                    {successLoads.map((file, index) => (
                                    <Col key={index} sm={12} md={6} lg={3} className='mb-3'>
                                        <Card className='base-card'>
                                            <Card.Body className='success-file-card'>
                                                <p className='mb-0'><strong>Rut:</strong> {file.dni}</p>
                                                <p className='mb-0'><strong>Archivo:</strong> {file.fileName}</p>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                            ))}
                        </Row>
                            </div>
                        ) : null}
                        {successLoads?.length > 0 && missingLoads?.length > 0 ? (
                            <hr className='mt-3' />
                        ) : null}
                        {missingLoads?.length > 0 ? (
                            <div className='text-center mt-3'>
                                <p className='text-danger' style={{fontSize:"18px"}}><strong>Los siguientes archivos no se cargaron:</strong></p>
                                <Row className='mt-3 gx-1'>
                                    {missingLoads.map((file, index) => (
                                    <Col key={index} sm={12} md={6} lg={3} className='mb-3'>
                                        <Card className='base-card'>
                                            <Card.Body className='error-file-card'>
                                                <p className='mb-0'><strong>Rut:</strong> {file.dni}</p>
                                                <p className='mb-0'><strong>Error:</strong> {file.msg}</p>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    ))}
                                </Row>
                                <p className='text-danger mt-3 mb-0' style={{fontSize:"16px"}}>Revisa que los rut esten correctos y el formato sea pdf xxxxxxx-x_dc</p>
                            </div>
                        ) : null}
                    </Col>
                </Card.Body>
                <Card.Footer>
                    {selectedFiles.length > 0 ? (
                    <div className='d-flex justify-content-center'>
                        <Button 
                            variant="success"
                            onClick={handleUploadFiles}
                            disabled={selectedFiles.length > 0 ? false : true}
                        >
                            Subir archivos
                        </Button>
                    </div>
                    ) : null}
                    {successLoads?.length > 0 || missingLoads?.length > 0 ? (                             
                        <div className='d-flex justify-content-center'>
                        
                        <Button 
                            variant="danger"
                            onClick={() => window.location.reload()}
                        >
                            Volver a la carga
                        </Button>
                        </div>
                    ) : null}
                </Card.Footer>
            </Card>
        </Col>
    </Row>
    </Container>
  );
}

export default MassiveExamFilesView;
import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { verifyToken } from 'store/slices/login/loginThunks';

import MassiveUploads from '../views/MassiveUploads/MassiveUploads';
import MassiveSchedule from 'views/MassiveSchedule/MassiveSchedule';
import MassiveResults from 'views/MassiveResults/MassiveResultsView';
import MassiveExamFilesView from 'views/MassiveExamFiles/MassiveExamFiles';
import LoginView from 'views/Login/Login';
import HomeView from 'views/Home';
import Header from 'views/Nav';

const RoutesMap = () => {
    const navigate = useNavigate();
    const disaptch = useDispatch();

    const RoutesWithoutNavBar = ({ children }) => {
        return children;
    }

    const ProtectedRoute = ({ redirectPath = '/login', children }) => {
        useEffect(() => {
            disaptch(verifyToken(navigate))
    
        }, []);
        return localStorage.getItem("token") !== null ? (
            <> 
                <Header />
                {children}
            </>
        ): (
            <Navigate to={redirectPath} replace />
        );
    };

    return ( 
        <Routes>
            <Route
                path="/login"
                element={
                    <RoutesWithoutNavBar>
                        <LoginView />
                    </RoutesWithoutNavBar>
                }
            />
            <Route
                path="/"
                element={
                    <ProtectedRoute>
                            <HomeView />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/cargas-masivas"
                element={
                    <ProtectedRoute>
                            <MassiveUploads />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/cargas-masivas-agendas"
                element={
                    <ProtectedRoute>
                            <MassiveSchedule />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/cargas-masivas-resultados"
                element={
                    <ProtectedRoute>
                            <MassiveResults />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/cargas-masivas-examenes"
                element={
                    <ProtectedRoute>
                            <MassiveExamFilesView />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="*" 
                element={<p>No se encontro la pagina: 404!</p>} 
            />
        </Routes>    
    );
}
 
export default RoutesMap;
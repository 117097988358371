import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        loading: false,
        logedIn: false,
        errors: {},
        errorLogin: false,
        errorMsg: {},
        token: '',
    },
    reducers: {
        initLogin: state => {
            state.loading = true;
        },
        loginSuccess: (state, action) => {
            state.loading = false;
            state.errorLogin = false;
            state.token = action.payload.token;
            state.logedIn = true;
        },
        initValidateSession: state => {
            state.loading = true;
        },
        validSession: (state) => {
            state.loading = false;
            state.errorLogin = false;
            state.logedIn = true;
            state.token = localStorage.getItem('token');
        },
        invalidSession: (state) => {
            localStorage.removeItem('token');
            state.loading = false;
            state.errorLogin = false;
            state.logedIn = false;
        },
        errorLogin: (state, action) => {
            state.loading = false;
            state.errorLogin = true;
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errorMsg;
        },
        cleanErrorLogin: state => {
            state.errorLogin = false;
            state.errorMsg = {};
            state.errors = {};
        },
        cleanLogin: state => {
            localStorage.removeItem('token');
            state.loading = false;
            state.errorLogin = false;
            state.errorMsg = {};
            state.token = '';
        }
    },
});

export const {
    initLogin,
    loginSuccess,
    token,
    errorLogin,
    initValidateSession,
    validSession,
    invalidSession,
    cleanErrorLogin,
    cleanLogin
} = loginSlice.actions;
import { configureStore } from '@reduxjs/toolkit';
import { massiveUploadsSlice } from './slices/massiveUploads/massiveUploadsSlice';
import { massiveResultsSlice } from './slices/massiveResults/massiveResultsSlice';
import { massiveExamFilesSlice } from './slices/massiveExamFiles/massiveExamFilesSlice';
import { loginSlice } from './slices/login/loginSlice';

export const store = configureStore({
    reducer: {
        massiveUploads: massiveUploadsSlice.reducer,
        massiveResults: massiveResultsSlice.reducer,
        massiveExamFiles: massiveExamFilesSlice.reducer,
        auth: loginSlice.reducer
    }
});
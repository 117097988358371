import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Container, Row, Col } from 'react-bootstrap'; // Assuming you are using Bootstrap
import companyLogo from '../assets/img/logo-happ-heart.svg'; // Update the path to your image
import '../assets/css/Home.css'; // Update the path to your CSS file

const HomeView = () => {
  const navigate = useNavigate();

  return (
    <Container className="text-center mt-5">
      <img src={companyLogo} alt="Company Logo" className="img-fluid mb-4" style={{ maxWidth: '300px' }} />
      <h1 className="home-title">Selecciona un módulo de cargas masivas</h1>
      <Row>
        <Col md={6}>
          <Card className="home-card shadow" onClick={() => navigate('/cargas-masivas')}>
            <Card.Body>
              <Card.Title style={{color:'#0050ff', fontWeight:'bold'}}>Carga de Empresas</Card.Title>
              <Card.Text>Carga de empresas y sucursales.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="home-card shadow" onClick={() => navigate('/cargas-masivas-agendas')}>
            <Card.Body>
              <Card.Title style={{color:'#0050ff', fontWeight:'bold'}}>Carga de Agenda</Card.Title>
              <Card.Text>Carga de agendas para operativo.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>
          <Card className="home-card shadow" onClick={() => navigate('/cargas-masivas-resultados')}>
            <Card.Body>
              <Card.Title style={{color:'#0050ff', fontWeight:'bold'}}>Carga de Resultados</Card.Title>
              <Card.Text>Carga de resultados de laboratorio.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="home-card shadow" onClick={() => navigate('/cargas-masivas-examenes')}>
            <Card.Body>
              <Card.Title style={{color:'#0050ff', fontWeight:'bold'}}>Carga de Examenes</Card.Title>
              <Card.Text>Carga de examenes en Pdf.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    );
  };

export default HomeView;
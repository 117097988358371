import { useState } from 'react';
import useUploadFile from 'hooks/useUploadFile/useUploadFile';
import { 
    Container, 
    Row, 
    Col, 
    Card, 
    Form,
    Button,
    ListGroup,
    Spinner
} from 'react-bootstrap';
import SweetAlert2 from 'react-sweetalert2';
import moment from 'moment';
import 'moment/min/locales';
import { Upload } from 'react-bootstrap-icons';
const initState = {
    file: '',
}
const MassiveSchedule = () => {
    moment.locale('es');
    const {
        state,
        uploadCheckFile,
        loading,
        companies,
        branches,
        admins,
        schedules,
        excelStatusOk,
        errorMsg,
        errorStatus,
        localLoading,
        hiddenFileInput,
        handleChangeFile,
        handleClickFile,
        handleClick,
        sendExcelFile,
        loadExcelData,
        loadExcelStatus,
        msgLoadExcelStatus,
    } = useUploadFile(initState);
    const { file } = state;
    const [confirm, setConfirm] = useState({
        show: true,
        text: 'Carga realizada con éxisto',
        type: 'success',
        icon: 'success',
    });
    return ( 
        <Container className='mt-3'>
        {localLoading !== null ? (
            <SweetAlert2
                {...confirm}
                didClose={() => {
                    window.location.href = '/cargas-masivas-agendas'
                }}
                onConfirm={result => {
                    window.location.href = '/cargas-masivas-agendas'
                }}
            />
        ) : null}
        <Row>
            <Col sm={12} lg={12} md={12} className='mt-3'>
                <Card>
                    <Card.Header className='text-center'>
                        <h5 className='title-module' >Módulo de cargas masivas de agendas</h5>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>Si no conoces el formato necesario, por favor descargar el Excel para preparar cargas masivas:</Card.Text>
                        <div className='d-flex'>
                            <Button 
                                variant="success"
                                onClick={() => handleClick('schedule')}
                            >
                                Descargar Formato Excel
                            </Button>
                        </div>
                    </Card.Body>
                    <Card.Body className='border-top'>
                        <Col sm={12} lg={12} md={12} className=''>
                            <div className='d-flex'>
                                <Form.Label className='mb-3 title-physical-exams mt-3'>Para hacer cargas masivas suba el <strong>Excel</strong> solicitado: </Form.Label>
                                <Button
                                    onClick={handleClickFile}
                                    className='btn-upload-file'
                                >
                                    <Upload />
                                    <p>Subir excel</p>
                                </Button>
                                <Form.Control 
                                    type='file'
                                    name='file'
                                    ref={hiddenFileInput}
                                    onChange={handleChangeFile}
                                    style={{display:'none'}} 
                                />
                                <p className='file-name'>{file ? file.name : null}</p>
                            </div>
                            {uploadCheckFile ? (
                                <div className='text-center mt-5'>
                                    <Button
                                        onClick={sendExcelFile('schedule')}
                                        className='btn-validate-file'
                                    >
                                        <p>Validar Excel</p>
                                    </Button>
                                </div>
                            ) : null}
                        </Col>
                    </Card.Body>
                    {excelStatusOk ? (
                        <Card.Body className='border-top'>
                            <Card.Header className='text-center'>
                                Elementos a cargar
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    {
                                        schedules &&
                                        (
                                            schedules.createSchedules.length ||
                                            schedules.existinsSchedules.length ||
                                            schedules.notCreateSchedules.length
                                        ) ? (
                                            <Col sm={4} lg={4} md={4} className='mt-3'>
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title>Agendas: </Card.Title>
                                                    </Card.Body>
                                                    <ListGroup className="list-group-flush">
                                                        {schedules.createSchedules && schedules.createSchedules.length ? (
                                                            <>
                                                                <ListGroup.Item>
                                                                    <strong>Agendas validas para crear:</strong>
                                                                    <ul>
                                                                        {schedules.createSchedules.map(schedule => (
                                                                            <li>{schedule.originName} - {schedule.medicalStageName} - {moment.utc(schedule.day).format('D MMMM YYYY')}</li>
                                                                        ))}      
                                                                    </ul>
                                                                </ListGroup.Item>
                                                            </>
                                                        ) : null}
                                                        {schedules.existinsSchedules && schedules.existinsSchedules.length ? (
                                                            <>
                                                                <ListGroup.Item>
                                                                    <strong>Agendas que ya existen:</strong>
                                                                    <ul>
                                                                        {schedules.existinsSchedules.map(schedule => (
                                                                            <li>{schedule.schedule.idOrigin} - {schedule.schedule.medicalStage} - {moment.utc(schedule.schedule.day).format('D MMMM YYYY')}</li>
                                                                        ))}      
                                                                    </ul>
                                                                </ListGroup.Item>
                                                            </>
                                                        ) : null}
                                                        {schedules.notCreateSchedules && schedules.notCreateSchedules.length ? (
                                                            <>
                                                                <ListGroup.Item>
                                                                    <strong>Agendas que no se pueden crear:</strong>
                                                                    <ul>
                                                                        {schedules.notCreateSchedules.map(schedule => (
                                                                            <li className=''>
                                                                                {schedule.schedule.idOrigin} - {schedule.schedule.medicalStage} - {moment.utc(schedule.schedule.day).format('D MMMM YYYY')} 
                                                                                <br></br>
                                                                                <strong>Razón: </strong> {schedule.msg}
                                                                            </li>
                                                                        ))}      
                                                                    </ul>
                                                                </ListGroup.Item>
                                                            </>
                                                        ) : null}
                                                    </ListGroup>
                                                </Card>
                                            </Col>
                                        ) : null
                                    }
                                </Row>
                                <Card.Footer className="text-center card-footer mt-5">
                                    {loadExcelStatus ? (
                                        <div className='text-center mt-5 mb-5'>
                                            <Spinner
                                                animation="border"
                                            />
                                        </div>
                                    ) : (
                                        <Button
                                            onClick={loadExcelData('schedule')} 
                                            className='btn-confirm'
                                        >
                                            Confirmar
                                        </Button>
                                    )}
                                </Card.Footer>
                            </Card.Body>
                        </Card.Body>
                    ) : null}
                </Card>
            </Col>
        </Row>
    </Container>
     );
}
 
export default MassiveSchedule;
import React from 'react';
import { useUploadResults } from 'hooks/useUploadFile/useUploadResults';
import { Upload } from 'react-bootstrap-icons';
import { ResultsGrid } from './ResultsGridComponent';
import { UploadSuccess } from './UploadSuccessComponent';
import { UploadError } from './UploadErrorComponent';
import { UploadStageError } from './UploadStageError';
import { LoadingSpinner } from './LoadingComponent';
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  Form,
  Button,
} from 'react-bootstrap';

const MassiveResultsView = () => {
    const { 
        loading,
        handleValidateResults, 
        handleDowloadExcel, 
        jsonResults,
        handleFileChange,
        fileName,
        hiddenFileInput,
        uploadCheckFile,
        handleClickFile,
        handleLoadNewExcel,
        handleLoadResults,
        uploadSuccess,
        uploadError,
        errorStagePatients,
        handleClosePopup,
        handleCheckPatient,
    } = useUploadResults();
    

  return ( 
    <Container className='mt-3'>
    <Row>
    {loading ? (<LoadingSpinner />) : null}
        <Col sm={12} lg={12} md={12} className='mt-3'>
            <Card>
                <Card.Header className='text-center'>
                    <h5 className='title-module' >Módulo de cargas masivas de resultados</h5>
                </Card.Header>
                <Card.Body>
                    <Card.Text>Si no conoces el formato necesario, por favor descargar el Excel para preparar cargas masivas:</Card.Text>
                    <div className='d-flex'>
                        <Button 
                            variant="success"
                            onClick={handleDowloadExcel}
                        >
                            Descargar Formato Excel
                        </Button>
                    </div>
                </Card.Body>
                {!jsonResults ? (
                    <Card.Body className='border-top'>
                        <Col sm={12} lg={12} md={12} className=''>
                            <div className='d-flex'>
                                <Form.Label className='mb-3 title-physical-exams mt-3'>Para hacer cargas masivas suba el <strong>Excel</strong> solicitado: </Form.Label>
                                <Button
                                    onClick={ handleClickFile }
                                    className='btn-upload-file'
                                >
                                    <Upload />
                                    <p>Subir Excel</p>
                                </Button>
                                <Form.Control 
                                    type='file'
                                    name='file'
                                    ref={hiddenFileInput}
                                    onChange={handleFileChange}
                                    style={{display:'none'}} 
                                />
                                <p className='file-name'>{fileName ? fileName : null}</p>
                            </div>
                            {uploadCheckFile ? (
                                        <div className='text-center mt-5'>
                                            <Button
                                                onClick={handleValidateResults}
                                                className='btn-validate-file'
                                            >
                                                <p>Validar Excel</p>
                                            </Button>
                                        </div>
                            ) : null}
                        </Col>
                    </Card.Body>
                ) : null}
                {jsonResults ? (
                        <Card.Body className='border-top'>
                            <ResultsGrid data={jsonResults} onCheckChange={handleCheckPatient}/>
                            <div className='text-center mt-5'>
                                <Button
                                    onClick={handleLoadNewExcel}
                                    className='btn-validate-file'
                                    style={{marginRight: '10px', backgroundColor: '#933A16', borderColor: '#933A16'}}
                                >
                                    <p>Volver a cargar Excel</p>
                                </Button>
                                <Button
                                    onClick={handleLoadResults}
                                    className='btn-validate-file'
                                    disabled={jsonResults.errors.length !== 0 ? true : false}
                                >
                                    <p>Cargar Resultados</p>
                                </Button>
                                <p className='mt-3' style={{color: 'red'}}>{jsonResults.errors.length !== 0 ? 'Por favor correjir errores y volver a cargar' : null}</p>
                            </div>
                        </Card.Body>      
                ) : null}
                
            </Card>
        </Col>
    </Row>
    {uploadSuccess && errorStagePatients.length < 1 ? (<UploadSuccess show={uploadSuccess} handleClose={handleClosePopup} />) : null}
    {errorStagePatients.length > 0 ? (<UploadStageError show={errorStagePatients} errorStagePatients={errorStagePatients} handleClose={handleClosePopup} />) : null}
    {uploadError ? (<UploadError show={uploadError} handleClose={handleClosePopup} />) : null}
</Container>
 );
};

export default MassiveResultsView;
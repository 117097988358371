import React from 'react';
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import logo from 'assets/img/logo-happ-heart.svg';
import { useLogin } from 'hooks/useUploadFile/useLogin';
import '../../assets/css/Login.css';

const loginState = {
  dni: '',
  password: ''
}

const Login = () => {
  const {
      state,
      errors,
      handlerChange,
      handlerSubmit
  } = useLogin(loginState);

  const {dni, password} = state;

  return ( 
      <Container>
      <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
              <Card className="shadow">
                  <Card.Body>
                      <div className="mb-3 mt-md-4">
                          <div className="content-logo">
                              <img src={logo} alt="description"/>
                          </div>
                          <div className="mb-3">
                              <Form
                                  onSubmit={handlerSubmit}
                              >
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                  <Form.Label className="text-center">
                                      Rut
                                  </Form.Label>
                                  <Form.Control 
                                      name="dni"
                                      type="dni" 
                                      placeholder="" 
                                      value={dni ? dni : ''}
                                      onChange={handlerChange} //validar rut ?? sera con rut el login ??
                                  />
                                  {errors && errors.dni ? (
                                      <p className='p-error-login'>{errors.dni}</p>
                                  ) : null}
                              </Form.Group>

                              <Form.Group
                                  className="mb-3"
                                  controlId="formBasicPassword"
                              >
                                  <Form.Label>Contraseña</Form.Label>
                                  <Form.Control 
                                      name="password" 
                                      type="password" 
                                      placeholder="******" 
                                      value={password ? password : ''}
                                      onChange={handlerChange}
                                  />
                                  {errors && errors.password ? (
                                      <p className='p-error-login'>{errors.password}</p>
                                  ) : null}
                              </Form.Group>
                              <div className="d-grid">
                                  <Button 
                                      variant="primary" 
                                      type="submit"
                                  >
                                      Continuar
                                  </Button>
                              </div>
                              </Form>
                          </div>
                      </div>
                  </Card.Body>
              </Card>
          </Col>
      </Row>
  </Container>
   );
}

export default Login;
import { 
    errorGetExcelData, 
    getExcelData, 
    initGetExcelData,
    initLoadExcelData,
    loadExcelData,
    initGetExcelFormat,
    getExcelFormat,
    cleanExcel,
    initGetScheduleExcelFormat,
    getScheduleExcelFormat, 
} from "./massiveUploadsSlice";
import { 
    getExcelFormatData,
    getScheduleExcelFormatData,    
    validateMassiveExcel,
    validateMassiveScheduleExcel,
    loadExcelDataIntoDatabase,
    loadScheduleExcelDataIntoDatabase,
} from "./services";


export const validateMassiveExcelAction = (body) => {
    return async dispatch => {
        dispatch(initGetExcelData());
        const response = await validateMassiveExcel(body);
        if (response.data.status === 200) {
            dispatch(getExcelData(response.data));
        } else {
            dispatch(errorGetExcelData(response.data));
        }
    }
}

export const validateMassiveScheduleExcelAction = (body) => {
    return async dispatch => {
        dispatch(initGetExcelData());
        const response = await validateMassiveScheduleExcel(body);
        if (response.data.status === 200) {
            dispatch(getExcelData(response.data));
        } else {
            dispatch(errorGetExcelData(response.data));
        }
    }
}

export const loadExcelDataAction = (body) => {
    return async dispatch => {
        dispatch(initLoadExcelData());
        const response = await loadExcelDataIntoDatabase(body);
        dispatch(loadExcelData(response.data));
    }
}

export const loadScheduleExcelDataAction = (body) => {
    return async dispatch => {
        dispatch(initLoadExcelData());
        const response = await loadScheduleExcelDataIntoDatabase(body);
        dispatch(loadExcelData(response.data));
    }
}

export const getExcelFormatAction = () => {
    return dispatch => {
        dispatch(initGetExcelFormat());
        dispatch(getExcelFormat());
        // dispatch(cleanExcel());
    }
}

export const getScheduleExcelFormatAction = () => {
    return dispatch => {
        dispatch(initGetScheduleExcelFormat());
        dispatch(getScheduleExcelFormat());
        // dispatch(cleanExcel());
    }
}

export const cleanExcelAction = () => {
    return dispatch => {
        dispatch(cleanExcel());
    }
}
import {  validateExcelResults, uploadJsonResults } from './massiveResultsServices';
import { 
  initGetExcelResults,
  getExcelResults,
  getResultsExcelFormat,
  cleanJsonResults, 
  cleanExcel,
  initUploadResults,
  uploadResultsSuccess,
  uploadResultsError,
  cleanUploadSuccess,
  cleanUploadError,
  cleanUploadStageError
} from './massiveResultsSlice';

export const validateExcelResultsAction = (file) => {
  return async dispatch => {
    try {
      dispatch(initGetExcelResults());
      const response = await validateExcelResults(file);
      if (response.status === 201) {
        dispatch(getExcelResults(response.data));
      } else {
        dispatch(cleanExcel());
      }
    } catch (error) {
      dispatch(cleanExcel());
    }
  }
}

export const uploadJsonResultsAction = (jsonResults, nextStage) => {
  return async dispatch => {
    dispatch(initUploadResults());
    try {
      const response = await uploadJsonResults(jsonResults, nextStage);
      if (response.status === 201 && response.data.statusCode === 201) {
        dispatch(uploadResultsSuccess(response.data.notProcessed));
      }
      else {
        dispatch(uploadResultsError());
      }
      //{statusCode: 400, message: 'Error al cargar, cargue nuevamente para verificar los resultados faltantes'} Error al cargar algun resultado
      //{statusCode: 201,notProcessed,message: 'CSV procesado satisfactoramente'};
    } catch (error) {
      console.log(error);
    }
  }
}

export const cleanJsonResultsAction = () => {
  return dispatch => {
    dispatch(cleanJsonResults());
  }}

export const getResultsExcelFormatAction = () => {
  return dispatch => {
    dispatch(getResultsExcelFormat());
  }
}

export const cleanExcelAction = () => {
  return dispatch => {
      dispatch(cleanExcel());
  }
}

export const cleanUploadSuccessAction = () => {
  return dispatch => {
    dispatch(cleanUploadSuccess());
  }
}

export const cleanUploadErrorAction = () => {
  return dispatch => {
    dispatch(cleanUploadError());
  }
}

export const cleanUploadStageErrorAction = () => {
  return dispatch => {
    dispatch(cleanUploadStageError());
  }
}
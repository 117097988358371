import axiosClient from "../../../config/axios";

const adminAuth = async authParams => {
  try {
      const headers = {'Content-Type': 'application/json'};
      const response = await axiosClient.post('/auth/login/admin', authParams, {headers});
      return response;
  } catch (error) {
      const {response} = error;
      return response;
  }
}

const verifyTokenSession = async token => {
  try {
      const headers = {
          'Content-Type': 'application/json',
          'authtoken': token
      };
      const response = await axiosClient.get('/auth/verify-token', {headers});
      return response;
  } catch (error) {
      const {response} = error;
      return response;
  }
}

export {
  adminAuth,
  verifyTokenSession
}
import axiosClient from "../../../config/axios";

const getExcelFormatData = async () => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.get(`/massive-uploads/get-excel-format`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}
const getScheduleExcelFormatData = async () => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.get(`/massive-uploads/get-schedule-excel-format`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const validateMassiveExcel = async body => {
    try {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        const response = await axiosClient.post('/massive-uploads/upload-massive-entity', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const validateMassiveScheduleExcel = async body => {
    try {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        const response = await axiosClient.post('/massive-uploads/upload-schedule-massive-entity', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const loadExcelDataIntoDatabase = async body => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.post('/massive-uploads/load-excel-data-into-database', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const loadScheduleExcelDataIntoDatabase = async body => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.post('/massive-uploads/load-schedule-excel-data-into-database', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

export {
    getExcelFormatData,
    getScheduleExcelFormatData,    
    validateMassiveExcel,
    validateMassiveScheduleExcel,
    loadExcelDataIntoDatabase,
    loadScheduleExcelDataIntoDatabase,
}
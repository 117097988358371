import { uploadMassiveExamFiles } from './massiveExamFilesService';
import { 
  initUploadMassiveExamFiles,
  uploadMassiveExamFilesSuccess,
  errorUploadMassiveExamFiles,
  cleanUploadSuccess
} from './massiveExamFilesSlice';

export const uploadMassiveExamFilesAction = (files) => {
  return async dispatch => {
    dispatch(initUploadMassiveExamFiles());
    try {
      const response = await uploadMassiveExamFiles(files);
      console.log(response.data);
      if (response.status === 201) {
        dispatch(uploadMassiveExamFilesSuccess({
          missingLoads:response.data.missingLoads, 
          successLoads:response.data.successLoads
        }));
      }
    } catch (error) {
      dispatch(errorUploadMassiveExamFiles());
    }
  }
}

export const cleanUploadSuccessAction = () => {
  return dispatch => {
    dispatch(cleanUploadSuccess());
  }
}
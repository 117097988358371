import { useState } from 'react';
import useUploadFile from 'hooks/useUploadFile/useUploadFile';
import { 
    Container, 
    Row, 
    Col, 
    Card, 
    Form,
    Button,
    ListGroup,
    Spinner
} from 'react-bootstrap';
import SweetAlert2 from 'react-sweetalert2';
import moment from 'moment';
import 'moment/min/locales';
import { Upload } from 'react-bootstrap-icons';
const initState = {
    file: '',
}
const MassiveUploads = () => {
    moment.locale('es');
    const {
        state,
        uploadCheckFile,
        loading,
        holdings,
        companies,
        branches,
        admins,
        schedules,
        excelStatusOk,
        errorMsg,
        errorStatus,
        localLoading,
        hiddenFileInput,
        handleChangeFile,
        handleClickFile,
        handleClick,
        sendExcelFile,
        loadExcelData,
        loadExcelStatus,
        msgLoadExcelStatus,
    } = useUploadFile(initState);
    const { file } = state;
    const [confirm, setConfirm] = useState({
        show: true,
        text: 'Carga realizada con éxisto',
        type: 'success',
        icon: 'success',
    });
    console.log(holdings); 
    console.log(companies); 
    console.log(branches); 
    console.log(admins); 
    return ( 
        <Container className='mt-3'>
            {localLoading !== null ? (
                <SweetAlert2
                    {...confirm}
                    didClose={() => {
                        window.location.href = '/cargas-masivas'
                    }}
                    onConfirm={result => {
                        window.location.href = '/cargas-masivas'
                    }}
                />
            ) : null}
            <Row>
                <Col sm={12} lg={12} md={12} className='mt-3'>
                    <Card>
                        <Card.Header className='text-center'>
                            <h5 className='title-module' >Módulo de cargas masivas</h5>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>Si no conoces el formato necesario, por favor descargar el Excel para preparar cargas masivas:</Card.Text>
                            <div className='d-flex'>
                                <Button 
                                    variant="success"
                                    onClick={() => handleClick('massive')}
                                >
                                    Descargar Formato Excel
                                </Button>
                            </div>
                        </Card.Body>
                        <Card.Body className='border-top'>
                            <Col sm={12} lg={12} md={12} className=''>
                                <div className='d-flex'>
                                    <Form.Label className='mb-3 title-physical-exams mt-3'>Para hacer cargas masivas suba el <strong>Excel</strong> solicitado: </Form.Label>
                                    <Button
                                        onClick={handleClickFile}
                                        className='btn-upload-file'
                                    >
                                        <Upload />
                                        <p>Subir excel</p>
                                    </Button>
                                    <Form.Control 
                                        type='file'
                                        name='file'
                                        ref={hiddenFileInput}
                                        onChange={handleChangeFile}
                                        style={{display:'none'}} 
                                    />
                                    <p className='file-name'>{file ? file.name : null}</p>
                                </div>
                                {uploadCheckFile ? (
                                    <div className='text-center mt-5'>
                                        <Button
                                            onClick={sendExcelFile('massive')}
                                            className='btn-validate-file'
                                        >
                                            <p>Validar Excel</p>
                                        </Button>
                                    </div>
                                ) : null}
                            </Col>
                        </Card.Body>
                        {excelStatusOk ? (
                            <Card.Body className='border-top'>
                                <Card.Header className='text-center'>
                                    Elementos a cargar
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                    {
                                            holdings &&
                                            (
                                                holdings.existinsHoldings.length ||
                                                holdings.createHoldings.length ||
                                                holdings.notCreateHoldings.length
                                            ) ? (
                                            <Col sm={4} lg={4} md={4} className='mt-3'>
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title>Holdings: </Card.Title>
                                                    </Card.Body>
                                                    <ListGroup className="list-group-flush">
                                                        {holdings.createHoldings && holdings.createHoldings.length ? (
                                                            <>
                                                                <ListGroup.Item>
                                                                    <strong>Holdings validos para crear:</strong>
                                                                    <ul>
                                                                        {holdings.createHoldings.map(holding => (
                                                                            <li>{holding.nombre}</li>
                                                                        ))}      
                                                                    </ul>
                                                                </ListGroup.Item>
                                                            </>
                                                        ) : null}
                                                        {holdings.existinsHoldings && holdings.existinsHoldings.length ? (
                                                            <>
                                                                <ListGroup.Item>
                                                                    <strong>Holdings que ya existen:</strong>
                                                                    <ul>
                                                                        {holdings.existinsHoldings.map(holding => (
                                                                            <li>{holding.holding.nombre}</li>
                                                                        ))}      
                                                                    </ul>
                                                                </ListGroup.Item>
                                                            </>
                                                        ) : null}
                                                        {holdings.notCreateHoldings && holdings.notCreateHoldings.length ? (
                                                            <>
                                                                <ListGroup.Item>
                                                                    <strong>Holdings que no se pueden crear:</strong>
                                                                    <ul>
                                                                        {holdings.notCreateHoldings.map(holding => (
                                                                            <li>
                                                                                {holding.holding.nombre}
                                                                                <br></br>
                                                                                <strong>Razón: </strong> {holding.msg}
                                                                            </li>
                                                                        ))}      
                                                                    </ul>
                                                                </ListGroup.Item>
                                                            </>
                                                        ) : null}
                                                    </ListGroup>
                                                </Card>
                                            </Col>
                                        ) : null}
                                        {
                                            companies &&
                                            (
                                                companies.createCompanies.length ||
                                                companies.existinsCompanies.length ||
                                                companies.notCreateCompanies.length
                                            ) ? (
                                            <Col sm={4} lg={4} md={4} className='mt-3'>
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title>Empresas: </Card.Title>
                                                    </Card.Body>
                                                    <ListGroup className="list-group-flush">
                                                        {companies.createCompanies && companies.createCompanies.length ? (
                                                            <>
                                                                <ListGroup.Item>
                                                                    <strong>Empresas validas para crear:</strong>
                                                                    <ul>
                                                                        {companies.createCompanies.map(company => (
                                                                            <li>{company.nombre}</li>
                                                                        ))}      
                                                                    </ul>
                                                                </ListGroup.Item>
                                                            </>
                                                        ) : null}
                                                        {companies.existinsCompanies && companies.existinsCompanies.length ? (
                                                            <>
                                                                <ListGroup.Item>
                                                                    <strong>Empresas que ya existen:</strong>
                                                                    <ul>
                                                                        {companies.existinsCompanies.map(company => (
                                                                            <li>{company.company}</li>
                                                                        ))}      
                                                                    </ul>
                                                                </ListGroup.Item>
                                                            </>
                                                        ) : null}
                                                        {companies.notCreateCompanies && companies.notCreateCompanies.length ? (
                                                            <>
                                                                <ListGroup.Item>
                                                                    <strong>Empresas que no se pueden crear:</strong>
                                                                    <ul>
                                                                        {companies.notCreateCompanies.map(company => (
                                                                            <li>
                                                                                {company.company.nombre}
                                                                                <br></br>
                                                                                <strong>Razón: </strong> {company.msg}
                                                                            </li>
                                                                        ))}      
                                                                    </ul>
                                                                </ListGroup.Item>
                                                            </>
                                                        ) : null}
                                                    </ListGroup>
                                                </Card>
                                            </Col>
                                        ) : null}
                                        {
                                            branches &&
                                            (
                                                branches.createBranches.length ||
                                                branches.existinsBranches.length ||
                                                branches.notCreateBranches.length
                                            ) ? (
                                                <Col sm={4} lg={4} md={4} className='mt-3'>
                                                    <Card>
                                                        <Card.Body>
                                                            <Card.Title>Sucursales: </Card.Title>
                                                        </Card.Body>
                                                        <ListGroup className="list-group-flush">
                                                            {branches.createBranches && branches.createBranches.length ? (
                                                                <>
                                                                    <ListGroup.Item>
                                                                        <strong>Sucursales validas para crear:</strong>
                                                                        <ul>
                                                                            {branches.createBranches.map(branch => (
                                                                                <li>{branch.nombre}</li>
                                                                            ))}      
                                                                        </ul>
                                                                    </ListGroup.Item>
                                                                </>
                                                            ) : null}
                                                            {branches.existinsBranches && branches.existinsBranches.length ? (
                                                                <>
                                                                    <ListGroup.Item>
                                                                        <strong>Sucursales que ya existen:</strong>
                                                                        <ul>
                                                                            {branches.existinsBranches.map(branch => (
                                                                                <li>{branch.branchOffice}</li>
                                                                            ))}      
                                                                        </ul>
                                                                    </ListGroup.Item>
                                                                </>
                                                            ) : null}
                                                            {branches.notCreateBranches && branches.notCreateBranches.length ? (
                                                                <>
                                                                    <ListGroup.Item>
                                                                        <strong>Sucursales que no se pueden crear:</strong>
                                                                        <ul>
                                                                            {branches.notCreateBranches.map(branch => (
                                                                                <li>
                                                                                    {branch.branch.nombre}
                                                                                    <br></br>
                                                                                    <strong>Razón: </strong> {branch.msg}
                                                                                </li>
                                                                            ))}      
                                                                        </ul>
                                                                    </ListGroup.Item>
                                                                </>
                                                            ) : null}
                                                        </ListGroup>
                                                    </Card>
                                                </Col>
                                            ) : null
                                        }
                                        {
                                            admins &&
                                            (
                                                admins.createAdmins.length ||
                                                admins.existinsAdmins.length ||
                                                admins.notCreateAdmins.length
                                            ) ? (
                                                <Col sm={4} lg={4} md={4} className='mt-3'>
                                                    <Card>
                                                        <Card.Body>
                                                            <Card.Title>Administradores: </Card.Title>
                                                        </Card.Body>
                                                        <ListGroup className="list-group-flush">
                                                            {admins.createAdmins && admins.createAdmins.length ? (
                                                                <>
                                                                    <ListGroup.Item>
                                                                        <strong>Admins validas para crear:</strong>
                                                                        <ul>
                                                                            {admins.createAdmins.map(admin => (
                                                                                <li>{admin.rut} - {admin.nombre}</li>
                                                                            ))}      
                                                                        </ul>
                                                                    </ListGroup.Item>
                                                                </>
                                                            ) : null}
                                                            {admins.existinsAdmins && admins.existinsAdmins.length ? (
                                                                <>
                                                                    <ListGroup.Item>
                                                                        <strong>Admins que ya existen:</strong>
                                                                        <ul>
                                                                            {admins.existinsAdmins.map(admin => (
                                                                                <li>{admin.rut} - {admin.nombre}</li>
                                                                            ))}      
                                                                        </ul>
                                                                    </ListGroup.Item>
                                                                </>
                                                            ) : null}
                                                            {admins.notCreateAdmins && admins.notCreateAdmins.length ? (
                                                                <>
                                                                    <ListGroup.Item>
                                                                        <strong>Admins que no se pueden crear:</strong>
                                                                        <ul>
                                                                            {admins.notCreateAdmins.map(admin => (
                                                                                <li>
                                                                                    {admin.admin.rut} - {admin.admin.nombre}
                                                                                    <br></br>
                                                                                    <strong>Razón: </strong> {admin.msg}
                                                                                </li>
                                                                            ))}      
                                                                        </ul>
                                                                    </ListGroup.Item>
                                                                </>
                                                            ) : null}
                                                        </ListGroup>
                                                    </Card>
                                                </Col>
                                            ) : null
                                        }
                                        {
                                            schedules &&
                                            (
                                                schedules.createSchedules.length ||
                                                schedules.existinsSchedules.length ||
                                                schedules.notCreateSchedules.length
                                            ) ? (
                                                <Col sm={4} lg={4} md={4} className='mt-3'>
                                                    <Card>
                                                        <Card.Body>
                                                            <Card.Title>Agendas: </Card.Title>
                                                        </Card.Body>
                                                        <ListGroup className="list-group-flush">
                                                            {schedules.createSchedules && schedules.createSchedules.length ? (
                                                                <>
                                                                    <ListGroup.Item>
                                                                        <strong>Agendas validas para crear:</strong>
                                                                        <ul>
                                                                            {schedules.createSchedules.map(schedule => (
                                                                                <li>{schedule.originName} - {schedule.medicalStageName} - {moment.utc(schedule.day).format('D MMMM YYYY')}</li>
                                                                            ))}      
                                                                        </ul>
                                                                    </ListGroup.Item>
                                                                </>
                                                            ) : null}
                                                            {schedules.existinsSchedules && schedules.existinsSchedules.length ? (
                                                                <>
                                                                    <ListGroup.Item>
                                                                        <strong>Agendas que ya existen:</strong>
                                                                        <ul>
                                                                            {schedules.existinsSchedules.map(schedule => (
                                                                                <li>{schedule.schedule.idOrigin} - {schedule.schedule.medicalStage} - {moment.utc(schedule.schedule.day).format('D MMMM YYYY')}</li>
                                                                            ))}      
                                                                        </ul>
                                                                    </ListGroup.Item>
                                                                </>
                                                            ) : null}
                                                            {schedules.notCreateSchedules && schedules.notCreateSchedules.length ? (
                                                                <>
                                                                    <ListGroup.Item>
                                                                        <strong>Agendas que no se pueden crear:</strong>
                                                                        <ul>
                                                                            {schedules.notCreateSchedules.map(schedule => (
                                                                                <li className=''>
                                                                                    {schedule.schedule.idOrigin} - {schedule.schedule.medicalStage} - {moment.utc(schedule.schedule.day).format('D MMMM YYYY')} 
                                                                                    <br></br>
                                                                                    <strong>Razón: </strong> {schedule.msg}
                                                                                </li>
                                                                            ))}      
                                                                        </ul>
                                                                    </ListGroup.Item>
                                                                </>
                                                            ) : null}
                                                        </ListGroup>
                                                    </Card>
                                                </Col>
                                            ) : null
                                        }
                                    </Row>
                                    <Card.Footer className="text-center card-footer mt-5 ">
                                        {loadExcelStatus ? (
                                            <div className='text-center mt-5 mb-5'>
                                                <Spinner
                                                    animation="border"
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {
                                                    !holdings.notCreateHoldings.length && 
                                                    !companies.notCreateCompanies.length &&
                                                    !branches.notCreateBranches.length &&
                                                    !admins.notCreateAdmins.length ? (
                                                        <Button
                                                            onClick={loadExcelData('massive')} 
                                                            className='btn-confirm'
                                                        >
                                                            Confirmar
                                                        </Button>
                                                    ) : null
                                                }
                                            </>
                                        )}
                                    </Card.Footer>
                                </Card.Body>
                            </Card.Body>
                        ) : null}
                    </Card>
                </Col>
            </Row>
        </Container>
     );
}
 
export default MassiveUploads;
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export const UploadSuccess = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Completado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Los resultados se cargaron exitosamente!</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleClose}>
          Volver
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import axiosClient from "../../../config/axios";

const uploadMassiveExamFiles = async (files) => {
  try {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });

    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const response = await axiosClient.post('/upload-exams-files/remote-upload', formData, {headers});
    return response;
  } catch (error) {
    return error;
  }
}

export {
  uploadMassiveExamFiles,
}

import { BrowserRouter } from "react-router-dom";
import { store } from "store/store";
import { Provider } from "react-redux";
import RoutesMap from 'routes/RoutesMap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/global.css';

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <RoutesMap />
      </Provider>
    </BrowserRouter>
  );
}

export default App;

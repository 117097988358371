import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadMassiveExamFilesAction, cleanUploadSuccessAction } from 'store/slices/massiveExamFiles/massiveExamFilesThunks';


export const useUploadExamFile = (initState) => {
  const [state, setState] = useState(initState);
  const [uploadCheckFile, setUploadCheckFile] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);

  console.log('hiddenFileInput', hiddenFileInput);

  const {
    loading,
    uploadSuccess,
    missingLoads,
    successLoads
  } = useSelector(state => state.massiveExamFiles);

  console.log('missingLoads', missingLoads);
  console.log('successLoads', successLoads);

  useEffect(() => {
    if (uploadSuccess) {
      setSelectedFiles([]);
      setState(initState);
      setUploadCheckFile(false);
      if (hiddenFileInput.current){
        hiddenFileInput.current.value = '';
      }
      dispatch(cleanUploadSuccessAction());
    }
  }, [uploadSuccess])

  
  const handleClickFile = e => {
    hiddenFileInput.current.click();
  };
  
  const handleFilesChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    setUploadCheckFile(true);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  const handleUploadFiles = () => {
    dispatch(uploadMassiveExamFilesAction(selectedFiles));
  };

  return {
    loading,
    uploadCheckFile,
    hiddenFileInput,
    selectedFiles,
    missingLoads,
    successLoads,
    handleFilesChange,
    handleClickFile,
    handleUploadFiles,
    handleRemoveFile
  };
}
import React from 'react';
import ReactLogo from 'assets/img/logo-happ-azul.svg'
import '../../assets/css/LoadingSpinner.css'; // Import CSS for animation

export const LoadingSpinner = () => {
  return (
    <div className="loading-spinner-overlay">
      <div className="loading-spinner">
        <img 
          src={ReactLogo} 
          alt="Loading..." 
          className="rotating-svg"
        />
      </div>
    </div>
  );
};
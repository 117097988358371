import React from 'react';
import '../../assets/css/ResultsGrid.css';

export const ResultsGrid = ({ data, onCheckChange }) => {
  const { patientsArray, errors, warnings, nextStage } = data;

  const errorMap = errors.reduce((acc, error) => {
    acc[error.dni] = { ...(acc[error.dni] || {}), [error.column]: error.error };
    return acc;
  }, {});

  const warningsMap = warnings.reduce((acc, warning) => {
    acc[warning.dni] = { ...(acc[warning.dni] || {}), [warning.column]: warning.warning };
    return acc;
  }, {});

  return (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th>#</th>
          <th>Rut</th>
          <th>Nombre</th>
          <th>Apellido</th>
          <th>Hba1c</th>
          <th>Glicemia</th>
          <th>Trigli.</th>
          <th>Colest.</th>
          <th>HDL</th>
          <th>LDL</th>
          <th>RAC</th>
          <th>Creat.</th>
          <th>Eritr.</th>
          <th>Gluco.</th>
          <th>Prote.</th>
          <th>Etapa</th>
        </tr>
      </thead>
      <tbody>
        {patientsArray.map((patient, index) => {
          
          return (
          <tr key={patient.rut}>
            <td className='basic'>{index + 1}</td>
            {Object.keys(patient).map((key) => {
              const value = patient[key] !== null ? patient[key] : '';
              const hasError = errorMap[patient.rut]?.[key];
              const hasWarning = warningsMap[patient.rut]?.[key];
              return(
              <td
                key={key}
                className={`${hasError ? 'has-error' : hasWarning ? 'has-warning' :  value === '' ? 'na-cell' : 'basic'}`}
              >
                {value}
                {hasError && (
                    <div className="error-tooltip">
                      {errorMap[patient.rut][key]}
                    </div>
                )}
                {hasWarning && (
                    <div className="warning-tooltip">
                      {warningsMap[patient.rut][key]}
                    </div>
                )}
              </td>
            )})}
            <td className='checkbox-cell'>
              <input 
                type="checkbox" 
                defaultChecked={nextStage.includes(patient.rut)} 
                onChange={() => onCheckChange(patient.rut)}
                />
            </td>
          </tr>
          )
        })}
      </tbody>
    </table>
  );
};
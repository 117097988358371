import { createSlice } from '@reduxjs/toolkit';

export const massiveExamFilesSlice = createSlice({
    name: 'massiveExamFiles',
    initialState: {
        loading: false,
        error: null,
        uploadSuccess: false,
        missingLoads: [],
        successLoads: [],
    },
    reducers: {
        initUploadMassiveExamFiles: state => {
            state.loading = true;
        },
        uploadMassiveExamFilesSuccess: (state, action) => {
            console.log('action', action.payload);
            state.loading = false;
            state.uploadSuccess = true;
            state.missingLoads = action.payload.missingLoads;
            state.successLoads = action.payload.successLoads;
        },
        errorUploadMassiveExamFiles: (state, action) => {
            state.loading = false;
        },
        cleanUploadSuccess: state => {
            state.uploadSuccess = false;
        }
    },
});

export const {
    initUploadMassiveExamFiles,
    uploadMassiveExamFilesSuccess,
    errorUploadMassiveExamFiles,
    cleanUploadSuccess
} = massiveExamFilesSlice.actions;